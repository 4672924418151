import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenuComponent} from '@pages/food/_shared/menu/menu.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '@shared/shared.module';
import {SharedPipesModule} from '../../../../_shared-modules/shared-pipes.module';



@NgModule({
  declarations: [
    MenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    SharedPipesModule
  ],
  exports: [
    MenuComponent
  ]
})
export class MenuModule { }
