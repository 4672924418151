import { Injectable } from '@angular/core';
import {MainService} from '@services/main.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IOfferDetails} from '@interfaces/IOffer';
import ICard from '@interfaces/cards/ICard';

@Injectable({
  providedIn: 'root'
})
export class CardService extends MainService {
  detailsUrl = '/cards';

  constructor( protected http: HttpClient ) {
    super( http );
  }

  getDetails( slug: string ): Observable<ICard> {
    return super.getDetails( slug );
  }
}
